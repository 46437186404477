import React, { createRef } from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import domtoimage from 'dom-to-image';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ThanksCouponModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canvasRef: createRef(null)
        }

        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '6px',
                width: 'max-content',
                height: 'max-content',
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    handleClick = () => {
        this.props.closeModal();
        this.props.myShopApiStore.clearReadyToOrderCartItems();
        this.props.ifaceMngr.setActiveTopMenu("ShopMenu");
    }
    
    handleDownloadCouponClick = () => {
        domtoimage.toJpeg(document.getElementById('my-coupon'), { quality: 0.95 })
        .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = 'my-coupon.jpeg';
            link.href = dataUrl;
            link.click();
        });
    }

    render() {
        const { modalIsOpen } = this.props;
        const { shop, postCouponData } = this.props.myShopApiStore;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.handleClick}>
                <div className={style.ModalContainer} id="my-coupon">
                    <div className={style.Content}>
                        <LazyLoadImage effect='blur' src={shop.image} width='120px'/>
                        <div className={style.BodyContent}>
                            <span>{`תודה שהזמנת מ${shop.name}!`}</span>
                            <span>{`דאגנו לך ל-${postCouponData.discount}% הנחה לקניה הבאה!`}</span>
                            <span>{`קוד הקופון הוא: ${postCouponData?.code}`}</span>
                            <span>להורדת הקופון <span onClick={this.handleDownloadCouponClick}>לחץ כאן</span></span>
                        </div>
                        <div className={style.FootContent}>
                            <span>{`חשוב לדעת!`}</span>
                            <span>{`הקופון נשלח למייל האישי שלך. תוקף הקופון הינו ${postCouponData.dateToFormat}`}</span>
                            <span>{`הקופון הינו אישי ואינו ניתן להעברה.`}</span>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ThanksCouponModal;