import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import MinOrderCostModal from "./MinOrderCostModal";
import ChangeAddressModal from './ChangeAddressModal'
// import OrderTimeModal from "./OrderTimeModal";
// import Calendar from 'react-calendar'

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class ShippingOptionForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: [],
            // orderTimeModalIsOpen: false
            minOrderCostModalIsOpen: false,
            changeAddressModalIsOpen: false
        }
    }
    optionBtnsStyle = (isDeliveryBtn = false) => {
        const { orderData, mainColor } = this.props.myShopApiStore;
        if(isDeliveryBtn){
            if(orderData.isDelivery){
                return{
                    backgroundColor: mainColor,
                    color: "#FFFF" 
                }
            }
            else{
                return{
                    backgroundColor: "#eeeeee",
                    color: "#9b9a9a" 
                }
            }
        } else {
            if(!orderData.isDelivery){
                return{
                    backgroundColor: mainColor,
                    color: "#FFFF" 
                }
            }
            else{
                return{
                    backgroundColor: "#eeeeee",
                    color: "#9b9a9a" 
                }
            }
        }
    }
    
    componentDidMount = async () => {
        this.setState({
            errors: []
        });
    }

    componentDidMount = () => {
        const { isDelivery } = this.props.myShopApiStore.orderData;
        this.props.setShippingOption(isDelivery);
    }
    componentDidUpdate() {
        const { isDelivery } = this.props.myShopApiStore.orderData;
        this.props.setShippingOption(isDelivery);
    }

    closeMinOrderCostModal = () => {
        this.setState({minOrderCostModalIsOpen: false})
    }

    openMinOrderCostModal = () => {
        this.setState({minOrderCostModalIsOpen: true})
    }

    closeChangeAddressModal = () => {
        this.setState({changeAddressModalIsOpen: false})
    }

    openChangeAddressModal = () => {
        this.setState({changeAddressModalIsOpen: true})
    }

    handleNextSubmit = (e) => {
        e.preventDefault();
        const { orderData, setDeliveryCost,
                calcTotalCartPrice, shop,
                cartPrice, userToken, userParams } = this.props.myShopApiStore;

        const errors = [];
        if(orderData.isDelivery){
            if(orderData.deliveryData.date === '' && !shop.withoutFuture_delivery){
                errors.push('deliveryTime')
            }
        }
        else{
            if(orderData.pickupData.date === '' && !shop.withoutFuture_pickup){
                errors.push('pickupTime')
            }
        }

        if(!errors.length){

            //reset delivery cost when user pickup order
            if(!orderData.isDelivery){
                setDeliveryCost(0);
                calcTotalCartPrice();

                this.props.nextStep(); 
            }
            else{ //checks minimal cost to order (only for delivery order)
                if(shop.minimalOrder > cartPrice){
                    this.openMinOrderCostModal();
                }
                else{
                    // if(userToken && userParams.city){
                    //     this.openChangeAddressModal();
                    // }
                    // else{
                        this.props.nextStep();
                    // }
                }
            }
        }
        else{
            this.setState({
                errors
            })
        }
    }
    getFormatDate = date => {
        const myDate = new Date(date * 1000);
        const myFormatedDate = myDate.toLocaleDateString();

        return myFormatedDate;
    }

    getFormatTime = (date=null, from ,to) => {
        const { orderData } = this.props.myShopApiStore;
        const { isDelivery, deliveryData, pickupData } = orderData;
        
        if(date){
            return `${this.getFormatDate(date)} ${from}-${to}`
        }
        if(isDelivery){
            return `${this.getFormatDate(deliveryData.date)} ${deliveryData.from}-${deliveryData.to}`
        }
        return `${this.getFormatDate(pickupData.date)} ${pickupData.from}-${pickupData.to}`
    }

    // isValidTime = (date) => {
    //     const myDate = new Date(date * 1000);
    //     const year = myDate.getFullYear();
    //     const month = myDate.getMonth();
    //     const day = myDate.getDate();

    //     const currDate = new Date();
    //     const currYear = currDate.getFullYear();
    //     const currMonth = currDate.getMonth();
    //     const currDay = currDate.getDate();

    //     if(currYear === year){
    //         if(currMonth === month){
    //             if(currDay === day){
    //                 const currHour = currDate.getHours();
    //                 const currMin = currDate.getMinutes();
    //                 const hour = myDate.getHours();
    //                 const min = myDate.getMinutes();
    //                 console.log("hour:",hour);
    //                 console.log("min:",min);
    //                 if(currHour === hour){
    //                     if(currMin > min){
    //                         return false;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     return true;
    // }

    canOrderedNow = () => {
        const { shop, orderData } = this.props.myShopApiStore;

        const days = {
            1: 'sunday',
            2: 'monday',
            3: 'tuesday',
            4: 'wednesday',
            5: 'thursday',
            6: 'friday',
            7: 'saturday'
        }
        
        const currDate = new Date();
        
        const currIntDay = currDate.getDay();
        const currHour = currDate.getHours();
        const currMin = currDate.getMinutes();

        // const times = orderData.isDelivery ? shop.deliveryTimes : shop.pickupTimes;
        const times = shop.workingTimes;

        for(let i=0 ; i<times.length ; i++){
            const strDay = times.weekday;
            const from = times.from;
            const to = times.to;

            const intHHFrom = parseInt(from.slice(0, 2));
            const intMMFrom = parseInt(from.slice(-2));
            const intHHTo = parseInt(to.slice(0, 2));
            const intMMTo = parseInt(to.slice(-2));
            
            if(days[currIntDay] === strDay){
                if(currHour === intHHFrom){
                    if(currMin >= intMMFrom){
                        if(currHour === intHHTo){
                            if(currMin <= intMMTo){
                                return true;
                            }
                        }
                        else if(currHour < intHHTo){
                            return true;
                        }
                    }
                }
                else if(currHour > intHHFrom){
                    if(currHour === intHHTo){
                        if(currMin <= intMMTo){
                            return true;
                        }
                    }
                    else if(currHour < intHHTo){
                        return true;
                    }
                }
            }
        }

        return false;
    }

    handleChange = (event) => {
        const { name, value } = event.target;  /// value===idx
        const { shop, setOrderData, orderData } = this.props.myShopApiStore;
        let date = '';
        let from = '';
        let to = '';
        
        const {deliveryData, pickupData, isDelivery} = orderData;

        if(isDelivery){
            if(name === "deliveryTime"){
                shop.deliveryTimes.forEach((dt, idx) => {
                    if(parseInt(value) === idx){
                        date = dt.date;
                        from = dt.from;
                        to = dt.to;
                    }
                })
                setOrderData('deliveryData', {...deliveryData, date, from, to})
            }
            else{
                setOrderData('deliveryData', {...deliveryData, [name]: value})
            }

            if(name === 'branch'){
                setOrderData('branch', value)
            }
        }
        else{
            if(name === "pickupTime"){
                shop.pickupTimes.forEach((pt, idx) => {
                    if(parseInt(value) === idx){
                        date = pt.date;
                        from = pt.from;
                        to = pt.to;
                    }
                })
                setOrderData('pickupData', {...pickupData, date, from, to})
            }
            else{
                setOrderData('pickupData', {...pickupData, [name]: value})
            }

            if(name === 'branch'){
                setOrderData('branch', value)
            }
        }

        let errors = this.state.errors;
        const eIndex = errors.indexOf(name);
        if (eIndex > -1) {
            errors.splice(eIndex, 1);
            this.setState({
                errors: errors,
            });
        }
    }

    render() {
        if(this.props.currentStep !== 1){
            return null;
        } 

        const { shop, orderData, setOrderData, mainColor, resetOrderData } = this.props.myShopApiStore;
        const { branches, orderTypes } = shop;
        const { isDelivery, deliveryData, pickupData } = orderData;

        const hasDeliveryOp = orderTypes ? orderTypes.some(ot => ot === 'delivery') : true;
        const hasPickupOp = orderTypes ? orderTypes.some(ot => ot === 'pickup') : true;
        
        return (
            <div className={style.Container}>
                <div className={style.Title}>
                    <div>דאגנו שיהיה לך הכי נוח שאפשר!</div>
                    <div>איך תרצה לקבל את ההזמנה שלך?</div>
                </div>
                <div className={style.UserChoosing}>
                    <div className={style.OptionBtns}>
                        {hasDeliveryOp && <button onClick={() => {resetOrderData(); setOrderData("isDelivery", true); }} style={this.optionBtnsStyle(true)}>משלוח</button>}
                        {hasPickupOp && <button onClick={() => {resetOrderData(); setOrderData("isDelivery", false);}} style={this.optionBtnsStyle()}>איסוף עצמי</button>}
                    </div>
                    {
                        isDelivery
                        ?
                            hasDeliveryOp
                            ?
                                <form onSubmit={this.handleNextSubmit}>
                                    <div>
                                        {
                                            (branches ? branches.length : false)
                                            ?  
                                                <select name="branch" onChange={this.handleChange} style={{border: this.state.errors.includes('branches') ? '1px solid #FF0000' : 'none'}}>
                                                    <option defaultValue="selected">מאיזה סניף תרצה להזמין?</option>
                                                    {shop.branches.map((branch, idx) => {
                                                        return(
                                                                <option value={branch.name} key={idx}>
                                                                    {branch.name}
                                                                </option>
                                                        )
                                                    })}
                                                </select>
                                            : 
                                                null
                                        }
                                        
                                        {
                                            !shop.withoutFuture_delivery
                                            ?
                                                <div>
                                                    <select name="deliveryTime" onChange={this.handleChange} style={{border: this.state.errors.includes('deliveryTime') ? '1px solid #FF0000' : 'none'}}>
                                                        <option defaultValue="selected">בחר תאריך למשלוח</option>
                                                        {shop.deliveryTimes.map((dt, idx) => {
                                                            return(
                                                                <option value={idx} key={idx}>
                                                                    {this.getFormatTime(dt.date, dt.from, dt.to)}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            :
                                                ''
                                        }
                                        <div className={style.Commands}>
                                            <textarea name="orderComment" value={deliveryData.orderComment} onChange={this.handleChange} placeholder="הוסף הערה להזמנה"/>
                                            <textarea name="deliveryGuyComment" value={deliveryData.deliveryGuyComment} onChange={this.handleChange} placeholder="הוסף הערה לשליח"/>
                                        </div>
                                    </div>
                                    <div className={style.Btns}>
                                        <button type="submit" style={{backgroundColor: mainColor}}>המשך</button>
                                        <button type="button" onClick={() => this.props.ifaceMngr.setActiveTopMenu("ShopMenu")}>חזור לתפריט</button>
                                    </div>
                                </form>
                            :
                                ''
                        :
                            hasPickupOp
                            ?
                                <form onSubmit={this.handleNextSubmit}>
                                    <div>
                                    {
                                        (branches ? branches.length : false)
                                        ? 
                                            <div>
                                                <select name="branch" onChange={this.handleChange} style={{border: this.state.errors.includes('branches') ? '1px solid #FF0000' : 'none'}}>
                                                    <option defaultValue="selected">מאיזה סניף תרצה לאסוף?</option>
                                                    {shop.branches.map((branch, idx) => {
                                                        return(
                                                                <option value={branch.name} key={idx}>
                                                                    {branch.name}
                                                                </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            : 
                                                null
                                    }
                                    
                                        {
                                            !shop.withoutFuture_pickup 
                                            ?
                                                <select name="pickupTime" onChange={this.handleChange} style={{border: this.state.errors.includes('pickupTime') ? '1px solid #FF0000' : 'none'}}>
                                                    <option defaultValue="selected">בחר תאריך לאיסוף</option>
                                                    {shop.pickupTimes.map((pt, idx) => {
                                                        return(
                                                                <option value={idx} key={idx}>
                                                                    {this.getFormatTime(pt.date, pt.from, pt.to)}
                                                                </option>
                                                        )
                                                    })}
                                                </select>
                                            :
                                                ''
                                        }
                                        <div className={style.Commands}>
                                            <textarea name="orderComment" value={pickupData.orderComment} onChange={this.handleChange} placeholder="הוסף הערה להזמנה"/>
                                        </div>
                                    </div>
                                    <div className={style.Btns}>
                                        <button type="submit" style={{backgroundColor: mainColor}}>המשך</button>
                                        <button type="button" onClick={() => this.props.ifaceMngr.setActiveTopMenu("ShopMenu")}>חזור לתפריט</button>
                                    </div>
                                </form>
                            :
                                ''
                    }
                </div>
                {/* <OrderTimeModal closeModal={this.closeOrderTimeModal} modalIsOpen={this.state.orderTimeModalIsOpen} orderType={orderData.isDelivery ? 'משלוחים' : 'באיסוף עצמי'}/> */}
                <MinOrderCostModal closeModal={this.closeMinOrderCostModal} modalIsOpen={this.state.minOrderCostModalIsOpen} />
                <ChangeAddressModal closeModal={this.closeChangeAddressModal}
                                    modalIsOpen={this.state.changeAddressModalIsOpen}
                                    nextStep={this.props.nextStep}
                                    
                                     />
            </div>
        )
    }
}

export default ShippingOptionForm
