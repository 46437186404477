import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
// import Login from '../Login';
// import Loading from '../Loading';
import MainScreen from '../MainScreen';
import cssVars from 'css-vars-ponyfill';
import io from 'socket.io-client';
import { api } from '../../Configs/api';
import StoresScreen from 'Components/StoresScreen';
import { my_env } from '../../Stores/myShopApi';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import Store from 'Components/StoresScreen/Store';
import ToskanaStoresScreen from 'Components/ToskanaStoresScreen';
import FastStoresScreen from 'Components/FastStoresScreen';

@inject(store => {
    const { helperStore, myShopApiStore } = store;
    return { helperStore, myShopApiStore };
})

@observer
class Root extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            mainColor: 'gray',
            // selectedStore: null,
            // storeOpened: false
        }
    }

    setLoading = () => {
        this.setState(prevState => ({
            loading: !prevState.loading
        }));
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    testInit = async () => {
        if ( this.props.helperStore.init ) {
            return true;
        }
        else {
            console.log('waiting...');
            await this.sleep(500);
            await this.testInit();
        }
    }
    shopInit = async () => {
        if ( this.props.myShopApiStore.init ) {
            return true;
        }
        else {
            console.log('waiting...');
            await this.sleep(500);
            await this.shopInit();
        }
    }
    logout = () => {
        this.setLoading();
        // this.props.groupeeApiStore.resetAll();
        // this.props.accountKitStore.resetAll();
        this.props.myShopApiStore.resetAll();
        setTimeout(() => {
            this.setLoading();
        }, 500);
    }

    componentDidMount = async () => {
        const { getShopsResp, setShopId, setShopName, shopsData, closeStore } = this.props.myShopApiStore;
        cssVars();
        // console.log("store:", this.props.myShopApiStore.getStore());
        // await this.testInit();
        await this.shopInit();
        //document.body.style.backgroundImage= this.props.myShopApiStore.setBodyBg();
        // this.props.myShopApiStore.setMainColor();
        // document.documentElement.style.setProperty('--mainColor', this.props.myShopApiStore.getMainColor());
        this.setState({mainColor: this.props.myShopApiStore.getMainColor()});


        ////////////////////////for single store!!!!! //////////////

        const { openStore, shopId,shop } = this.props.myShopApiStore;
        const isSingleStore = !shopsData && shopId === 1;
        let storeName = shop ? shop.name : ""
        isSingleStore && await openStore(shopId, storeName)

        ////////////////////////////////////////////////////////////



        if(this.props.myShopApiStore.user_info != null)
        {
            /*
            const socket = await io(api.groupee_api.socketUrl);

            await this.props.groupeeApiStore.getRegister(this.props.accountKitStore.user_info.phone.number);

            if (this.props.groupeeApiStore.user != null) {
                //console.log('message-'+this.props.groupeeApiStore.user.id);
                socket.emit('login', { jwtoken: this.props.groupeeApiStore.jwtoken, member_id: this.props.groupeeApiStore.user.id }, (data) => {
                    //console.log(data);
                    if(data.status === 1) {
                        socket.on('message-'+this.props.groupeeApiStore.user.id, (data) => {
                            //console.log(data);
                            if(data.type === 'reply') this.props.groupeeApiStore.addReplyFromSocket(data, this.props.helperStore.selectedGroupId);
                            else {
                                this.props.groupeeApiStore.addMessageFromSocket(data, this.props.helperStore.selectedGroupId, this.props.helperStore.selectedTopicId);

                                if(this.props.helperStore.selectedGroupId === data.group_id && this.props.helperStore.selectedTopicId === data.topic_id) this.props.helperStore.setGroupMessagesScroll(true);
                            };
                        });
                        socket.on('member-read-message-'+this.props.groupeeApiStore.user.id, (data) => {
                            //console.log(data);
                            this.props.groupeeApiStore.addReadMessageFromSocket(data);
                        });
                        socket.on('delete-member-message-'+this.props.groupeeApiStore.user.id, (data) => {
                            this.props.groupeeApiStore.deleteMessageFromSocket(data);
                        });
                        socket.on('group-update-notification-member-'+this.props.groupeeApiStore.user.id, (data) => {
                            this.props.groupeeApiStore.updateGroupFromSocket(data);
                        });
                        socket.on('member-was-removed-from-group-'+this.props.groupeeApiStore.user.id, (data) => {
                            this.props.groupeeApiStore.deleteGroupFromSocket(data);
                        });
                    }
                });
            }
            */
        } else {
            this.props.myShopApiStore.generateCsrf();
        }

        this.setLoading();

        //document.body.addEventListener('click', this.props.helperStore.hideContextMenu);
        //document.body.addEventListener('click', this.props.helperStore.hideGroupUserContextMenu);
    }

    componentWillUnmount = () => {

        //document.body.removeEventListener('click', this.props.helperStore.hideContextMenu);
        //document.body.removeEventListener('click', this.props.helperStore.hideGroupUserContextMenu);
    }

    // openStore = async (shopId, shopName) => {
    //     console.log("in openStore")
    //     const { setShopId,setShopName, getApiResp_SetShop, getApiResp, setInit } = this.props.myShopApiStore;
    //     setShopId(shopId);
    //     setShopName(shopName);
    //     // await getApiResp_SetShop();
    //     await setInit();
    //     console.log("openStore(Func) -> my_env:", my_env)
    //     this.setState({storeOpened: true})
    // }

    // closeStore = () => {
    //     this.setState({storeOpened: false})
    // }

    // getShop = (id) => {
    //     const { shopsData, openStore } = this.props.myShopApiStore;
    //     for(let i=0 ; i<shopsData.shops.length ; i++){
    //         if(shopsData.shops[i].id == id){
    //             return shopsData.shops[i];
    //         }
    //     }
    // }

    // isStore = async (id) => {
    //     const { shopsData, getShopsResp, setShopId, setShopName} = this.props.myShopApiStore;
    //     if(!shopsData){
    //         setShopId(null);
    //         setShopName(null);
    //         await getShopsResp();
    //     }
    //     if(shopsData){
    //         for(let i=0 ; i<shopsData.shops.length ; i++){
    //             if(shopsData.shops[i].id === parseInt(id)){
    //                 return true;
    //             }
    //         }
    //     }
    //     return false;
    // }

    // tryOpenStore = async () => {
    //     const { openStore } = this.props.myShopApiStore;
    //     const pathname = window.location.pathname.slice(1);

    //     const isAStore = await this.isStore(pathname);
    //     if(isAStore){
    //         const { name, id } = this.getShop(pathname);
    //         await openStore(id, name);
    //     }
    // }


    // renderA11y = () => {
    //     return(
    //             <script>
    //             {
    //                 (function(document, tag) {
    //                     var script  = document.createElement(tag);
    //                     var element = document.getElementsByTagName('body')[0];
    //                     script.src = 'https://acsbap.com/apps/app/assets/js/acsb.js'; 
    //                     script.async = true; 
    //                     script.defer = true;
    //                     (typeof element === 'undefined' ? document.getElementsByTagName('html')[0] : element).appendChild(script);
    //                     script.onload = function() {
    //                         acsbJS.init({
    //                             clientId          : 1207,
    //                             clientKey         : 'nm8cYKyGDXxCwxTzwh2Q',
    //                             wlbl              : 'Nagishly',
    //                             statementLink     : '',
    //                             feedbackLink      : '',
    //                             showAllActions    : false,
    //                             keyNavStrong      : false,
    //                             hideMobile        : false,
    //                             hideTrigger       : false,
    //                             language          : 'he',
    //                             focusInnerColor   : '#146FF8',
    //                             focusOuterColor   : '#ff7216',
    //                             leadColor         : '#146FF8',
    //                             triggerColor      : '#146FF8',
    //                             size              : 'big',
    //                             position          : 'right',
    //                             triggerRadius     : '50%',
    //                             triggerPositionX  : 'right',
    //                             triggerPositionY  : 'bottom',
    //                             triggerIcon       : 'default',
    //                             triggerSize       : 'medium',
    //                             triggerOffsetX    : 20,
    //                             triggerOffsetY    : 20,
    //                             usefulLinks       : {  },
    //                             mobile            : {
    //                                 triggerSize       : 'small',
    //                                 triggerPositionX  : 'right',
    //                                 triggerPositionY  : 'bottom',
    //                                 triggerOffsetX    : 0,
    //                                 triggerOffsetY    : 0,
    //                                 triggerRadius     : '0'
    //                             }
    //                         });
    //                     };
    //                 }(document, 'script'))
    //             }
    //         </script>
    //     )
    // }

    render() {
//         const { info } = this.props.myShopApiStore;
        const { storeOpened, shopsData, shopId, closeStore, openStore, my_env } = this.props.myShopApiStore;
        const isSingleStore = !shopsData && shopId === 1; // changed for 'katif'
        // const isSingleStore = !shopsData; //for 'Katif'

        // const pathname = window.location.pathname.slice(1);
        // pathname && this.tryOpenStore();
        return(
            <>
                {
                    storeOpened || isSingleStore
                    ?
                        <MainScreen mainColor={this.mainColor}/>
                    :
                        my_env.url.indexOf("toskana") >= 0
                        ?
                            <ToskanaStoresScreen openStore={this.openStore} />
                        :
                            my_env.url.indexOf("fast") >= 0
                            ?
                                <FastStoresScreen openStore={this.openStore} />
                            :
                                <StoresScreen openStore={this.openStore} />
                }
            </>



            // <BrowserRouter>
            //     <Route exact path='/'>
            //         <StoresScreen openStore={this.openStore} />
            //     </Route>
            //     <Route exact path={`/${window.location.pathname.slice(1)}`}>
            //         {!shopsData && <StoresScreen openStore={this.openStore} />}
            //         {storeOpened && <MainScreen mainColor={this.mainColor} />}
            //     </Route>
            // </BrowserRouter>



            // this.state.loading ? (
            //     <Loading />
            // ) : (
            //     info === null ? (
            //         <Login />
            //     ) : (
            //         <MainScreen logout={this.logout} />
            //     )

            // )
        )
    }
}

export default Root;

