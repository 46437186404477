import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import pin from '../../../assets/pin.png'
import bike from "../../../assets/bike.svg"

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class Store extends React.Component {
    render() {
        const { shop, openLoginBusStoreModal } = this.props;
        const { openStore, my_env } = this.props.myShopApiStore;
        // const isPastaDioroSite = my_env.url.indexOf("pastadioro") >= 0
        const isPastaDioroSite = true;
        return (
            <div className={style.Container} onClick={() => 
                                                    {shop.isBusiness ?
                                                                         openLoginBusStoreModal(shop)
                                                                     :
                                                                         openStore(shop.id, shop.name)}}>
                <div className={shop.isMakingDelivery ? style.greenCircle : style.redCircle}></div>
                <div className={style.ShopImage}> 
                    <img src={shop.image}/>
                </div>
                <div className={style.NameAndAddress}>
                    <div className={style.ShopName}> 
                        <span>{shop.name}</span>
                    </div>
                    {
                        isPastaDioroSite
                        ?
                            <div className={style.shopType}>
                                {shop.isBusiness ? 'עסקי' : 'פרטי'}
                            </div>
                        :
                            ''
                    }
                    <div className={style.ShopAddress}>
                        <div className={style.ShopIconAddress}>
                            <img src={pin} />
                        </div>
                        <div className={style.ShopTextAddress}>
                            <span>{shop.address}</span>
                        </div>
                    </div>
                </div>
                {
                    shop.deliveryTimeMinutesFrom && shop.deliveryTimeMinutesTo 
                    ?
                        <div className={style.DeliveryTime}>
                            <div className={style.bikeImg}>
                                <img alt="delivery bike" src={bike}></img>
                            </div>
                            <div className={style.from}>{shop.deliveryTimeMinutesTo}</div>
                            -
                            <div className={style.to}>{`${shop.deliveryTimeMinutesFrom} דק'`}</div>
                            
                                
                        </div>

                    :
                        null 
                }

            </div>
        )
    }
}

export default Store
