import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import bikeIcon from '../../../../../../assets/bike.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ChangeAddressModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '6px',
                width: 'max-content',
                height: 'max-content'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    handleCloseClick = () => {
        this.props.closeModal();
    }

    clearUserAddress = () => {
        const { setUserParams } = this.props.myShopApiStore;
        setUserParams("streetName", '');
        setUserParams("streetNumber", '');
        setUserParams("floorNumber", '');
        setUserParams("entranceCode", '');
        setUserParams("apartmentNumber", '');
        setUserParams("city", '');
    }

    handleApproveClick = () => {
        this.props.nextStep();
        this.props.closeModal();
    }

    handleChangeAddressClick = () => {
        this.clearUserAddress();
        this.props.nextStep();
        this.props.closeModal();
    }
    
    render() {
        const { modalIsOpen } = this.props;
        const { shop, userParams, userToken, mainColor } = this.props.myShopApiStore;
        console.log("userParams:", userParams)
        console.log("userToken:", userToken)
        const { city, streetName, streetNumber, floorNumber, apartmentNumber } = userParams;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.handleClick}>
                <div className={style.ModalContainer}>
                    {/* <div className={style.CloseBtn}>
                        <span onClick={this.handleCloseClick}>✕</span>
                    </div> */}
                    <div className={style.Body}>
                        <div className={style.BodyContent} style={{color: mainColor}}>
                            <span>מעוניין לבצע משלוח לכתובת:</span>
                            <span>{`${city}, ${streetName} ${streetNumber}`}</span>
                            {
                                floorNumber
                                ?
                                    <span>{`קומה ${floorNumber}`}</span>
                                :
                                    ""
                            }
                            {
                                apartmentNumber
                                ?
                                    <span>{`דירה ${apartmentNumber}`}</span>
                                :
                                    ""
                            }
                            
                        </div>
                        <div className={style.BikeImage}>
                            <LazyLoadImage effect='blur' src={bikeIcon} width='100%'/>
                        </div>
                        <div className={style.BodyButtons}>
                            <button onClick={this.handleApproveClick} style={{border: `1px solid ${mainColor}`, backgroundColor: mainColor,
                                            color: '#FFF'}}>
                                אישור
                            </button>
                            <button onClick={this.handleChangeAddressClick} style={{border: `1px solid ${mainColor}`, backgroundColor: '#FFF',
                                            color: mainColor}}>
                                החלף כתובת
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ChangeAddressModal;