import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
// import Modal from 'react-modal';


@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class CreditIframe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: null
        }
    }

    componentDidMount = async() => {
        const { checkPreorderResp } = this.props.myShopApiStore;
        const { parentRef } = this.props;

        let resp = null;
        const interval = setInterval(async () => {
            resp = await checkPreorderResp();
            if(resp && resp.data.errorCode === '0'){
                window.top.postMessage('close-iframe', parentRef)
            }
        }, 3000);

        if(resp && resp.data.errorCode === '0'){
            clearInterval(interval);
            resp = null;
        }
        this.setState({interval});
    }


    componentWillUnmount = () => {
        this.state.interval && clearInterval(this.state.interval)
    }

    render() {
        const { iframeRef } = this.props;
        const { sessionUrl } = this.props.myShopApiStore;
        
        return (
            <iframe
                ref={iframeRef}
                src={sessionUrl}
                width="100%"
                height="100%"
                style={{
                    borderRadius: "12px",
                    marginTop: "20px",
                }}>
            </iframe>
        )
    }
}

export default CreditIframe;