import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import ValidSMSModal from '../../ValidSMSModal';
import ThanksModal from "../../ThanksModal";
import ThanksCouponModal from "../../ThanksCouponModal";
import TermsModal from "../TermsModal";
import APILoader from "../../APILoader";
import OrderTimeModal from "../OrderTimeModal";

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class SimplePayOpForm extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
            errors: [],
            // name: this.props.myShopApiStore.userParams.name,
            // phone: this.props.myShopApiStore.userParams.phone,
            // email: this.props.myShopApiStore.userParams.email,
            isCheckedTerms: this.props.myShopApiStore.userToken ? true : false,
            smsModalIsOpen: false,
            thanksModalIsOpen: false,
            termsModalIsOpen: false,
            apiLoaderIsOpen: false,
            orderTimeModalIsOpen: false
        }
    }
    
    handleChange = e => {
        const { setUserParams } = this.props.myShopApiStore; 
        const { name, value, checked } = e.target;

        if(name === 'isCheckedTerms'){
            this.setState({[name]: checked})
        }
        else{
            setUserParams(name, e.target.value)
        }

        let errors = this.state.errors;
        const eIndex = errors.indexOf(name);
        if (eIndex > -1) {
            errors.splice(eIndex, 1);
            this.setState({
                errors: errors,
            });
        }
    }

    // componentDidMount = async () => {
    //     this.setState({
    //         errors: []
    //     });
    // }

    errorConditions = () => {
        const { userParams } = this.props.myShopApiStore;

        let errors = [];
        if(userParams.name.trim() === ''){
            errors.push('name')
        }
        if(userParams.phone.trim() === '' || !/^\d+$/.test(userParams.phone) || userParams.phone.length !== 10){
            errors.push('phone')
        }
        if(userParams.email.trim() === '' || !this.validateEmail(userParams.email.trim())){
            errors.push('email')
        }
        if(!this.state.isCheckedTerms){
            errors.push('isCheckedTerms')
        }

        return errors;
    }

    preLoadApi = async () => {
        const { createSessionResp, makePreorderResp, creditUniqueId} = this.props.myShopApiStore;

        await makePreorderResp();
        await createSessionResp(creditUniqueId);
    }

    handleCreditClick = async () => {
        const { setOrderData, registerUser, shop} = this.props.myShopApiStore;
        const errors = this.errorConditions();
        if(!errors.length){
            this.openAPILoader()
            setOrderData("isCredit", true)

            // const regResp = await registerUser();
            this.openAPILoader()
            const regResp = await registerUser();
            this.closeAPILoader();

            if(regResp.data){
                await this.preLoadApi();
                this.props.nextStep()
            }
            else{
                this.openSmsModal()
            }
            this.closeAPILoader();

        }
        else{
            this.setState({
                errors
            })
        }
    }

    handleStoreClick = async () => {
        const { setOrderData, registerUser, setUserParams, userParams, makeOrderResp, userToken} = this.props.myShopApiStore;
        
        const errors = this.errorConditions();

        if(!errors.length){
            setOrderData("isCredit", false);

            this.openAPILoader()
            const regResp = await registerUser();
            this.closeAPILoader();

            if(regResp.data){
                this.openAPILoader();
                const orderResp = await makeOrderResp();
                this.closeAPILoader();

                if(orderResp.data.errorCode !== '0'){
                    if(orderResp.data.errorCode === 'DATE_ERROR'){
                        this.openOrderTimeModal();
                    }
                    else{
                        this.openOrderTimeModal();
                        console.log('ERROR_CODE:', orderResp.data.errorCode);
                    }
                }
                else{
                    this.openThanksModal();
                }
            } 
            else{
                this.openSmsModal();
            }
        }
        else{
            this.setState({
                errors
            })
        }
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    closeOrderTimeModal = () => {
        this.setState({orderTimeModalIsOpen: false})
    }

    openOrderTimeModal = () => {
        this.setState({orderTimeModalIsOpen: true})
    }

    openAPILoader = () => {
        this.setState({apiLoaderIsOpen: true})
    }

    closeAPILoader = () => {
        this.setState({apiLoaderIsOpen: false})
    }

    openSmsModal = () => {
        this.setState({smsModalIsOpen: true})
    }
    closeSmsModal = async () => {
        this.setState({smsModalIsOpen: false})
        
        const { userToken, makeOrderResp, orderData } = this.props.myShopApiStore;
        if(userToken && !orderData.isCredit){//makes order for new user
            this.openAPILoader();
            const resp = await makeOrderResp();
            this.closeAPILoader();

            if(resp.data.errorCode !== '0'){
                if(resp.data.errorCode === 'DATE_ERROR'){
                    this.openOrderTimeModal();
                }
                else{
                    console.log('ERROR_CODE:', resp.data.errorCode);
                }
            }
            else{
                this.openThanksModal();
            }
        }
        else if(userToken && orderData.isCredit){//makes next-step for new user
            this.openAPILoader()
            await this.preLoadApi();
            this.props.nextStep()
            this.closeAPILoader();
        }
    }

    openThanksModal = () => {
        this.setState({thanksModalIsOpen: true})
    }
    closeThanksModal = () => {
        this.setState({thanksModalIsOpen: false})
    }

    openTermsModal = () => {
        this.setState({termsModalIsOpen: true})
    }

    closeTermsModal = () => {
        this.setState({termsModalIsOpen: false})
    }

    render() {
        if(this.props.currentStep !== 2){
            return null;
        } 
        const { orderData, userParams, mainColor,
                shop, hasPostCoupon } = this.props.myShopApiStore;

        const { paymentTypes } = shop;
        const canPayByCredit = paymentTypes?.some(pt => pt === "credit");
        const canPayByCash = paymentTypes?.some(pt => pt === "cash");

        return (
            <div className={style.Container}>
                <div className={style.TitleText}>
                    נשמח לראות אותך!
                    <br/>
                    במידה ונצטרך ליצור איתך קשר, אנא מלא כאן את הפרטים שלך
                </div>
                <div className={style.InputFields}>
                    <form>
                        <div className={style.Inputs}>
                            <input name="name" placeholder="שם מלא" value={userParams.name} onChange={this.handleChange} style={{border: this.state.errors.includes('name') ? '1px solid #FF0000' : 'none'}}/>
                            <input name="phone" placeholder="טלפון נייד" value={userParams.phone} onChange={this.handleChange}  style={{border: this.state.errors.includes('phone') ? '1px solid #FF0000' : 'none'}}/>
                            <input name="email" placeholder="מייל" value={userParams.email} onChange={this.handleChange}  style={{border: this.state.errors.includes('email') ? '1px solid #FF0000' : 'none'}}/>
                            <div className={style.CheckTerm} >
                                <input type="checkbox" name="isCheckedTerms" checked={this.state.isCheckedTerms} onChange={this.handleChange}/>
                                <span style={{color: this.state.errors.includes('isCheckedTerms') ? '#FF0000' : 'rgb(255,255,255)'}}>
                                    אני מאשר/ת שקראתי את
                                    {" "}
                                    <span className={style.TextTermBtn} onClick={this.openTermsModal} style={{color: this.state.errors.includes('isCheckedTerms') ? '#FF0000' : 'rgb(255,255,255)'}}>התקנון</span>
                                    {" "}
                                    ואת מדיניות הפרטיות ואני מסכים/ה לתנאים
                                </span>
                            </div>
                        </div>
                        <div className={style.Btns}>
                        {
                            shop.isBusiness
                            ?
                                <button type="button" onClick={this.handleStoreClick} style={{backgroundColor: mainColor}}>שלח</button>
                            :   
                                <>
                                    {canPayByCredit ? <button type="button" onClick={this.handleCreditClick} style={{backgroundColor: mainColor}}>תשלום באשראי</button> : null}
                                    {canPayByCash ? <button type="button" onClick={this.handleStoreClick} style={{backgroundColor: mainColor}}>תשלום בחנות</button> : null}
                                </>
                        }
                            <button type="button" onClick={this.props.prevStep} id={style.BackBtn}>חזור</button>
                        </div>
                    </form>
                </div>
                <div className={style.Modals}>
                    <TermsModal closeModal={this.closeTermsModal} modalIsOpen={this.state.termsModalIsOpen} />
                    <ValidSMSModal closeModal={this.closeSmsModal} modalIsOpen={this.state.smsModalIsOpen} />
                    {
                        hasPostCoupon
                        ?
                            <ThanksCouponModal closeModal={this.closeThanksModal} modalIsOpen={this.state.thanksModalIsOpen} />
                        :
                            <ThanksModal closeModal={this.closeThanksModal} modalIsOpen={this.state.thanksModalIsOpen} />
                    }
                    <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
                    <OrderTimeModal closeModal={this.closeOrderTimeModal} modalIsOpen={this.state.orderTimeModalIsOpen} orderType={orderData.isDelivery ? 'משלוחים' : 'באיסוף עצמי'}/>
                </div>
            </div>
        )
    }
}

export default SimplePayOpForm
