import logo from "../../assets/logo.png";
import bigappslogo from "../../assets/bigappslogo.png";
import loginicon from "../../assets/user.png";
import React, { Component } from "react";
import style from "./style.module.css";
// import Popup from './Popup';
import { observer, inject } from "mobx-react";
import TopMenu from "./TopMenu";
import AppBody from "./AppBody";
import Maintenance from "./Maintenance";
import WelcomeModal from './WelcomeModal';
import stores from '../../assets/stores.png'
import whatsapp from '../../assets/whatsapp.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { my_env } from '../../Stores/myShopApi'
import cartImg from "assets/cart_x2.png";
import NumberFormat from "react-number-format";
import { useScreenType } from '../../hooks/useScreenType';
import CarouselModal from "./AppBody/ShopMenu/ItemList/Item/CarouselModal";
import ReactWhatsapp from 'react-whatsapp';
import whatsappIcon from "../../assets/whatsapp.svg"
import facebook from "../../assets/facebook.svg"
import Footer from "./Footer";
import blackCart from 'assets/cart/blackCart.png'
import redCart from 'assets/cart/redCart.png'
import blueCart from 'assets/cart/blueCart.png'
import greenCart from 'assets/cart/greenCart.png'
import yellowCart from 'assets/cart/yellowCart.png'

@inject((store) => {
  const { ifaceMngr, myShopApiStore } = store;
  return { ifaceMngr, myShopApiStore };
})
@observer
class MainScreen extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      modalIsOpen: true, //welcome modal
      carouselModalIsOpen: true,
      isMobile: window.innerWidth <= 850,
      basketIsOpen: false,
    };
  }

  // openCarouselModalIsOpen = () => {
  //   this.setState({carouselModalIsOpen: true});
  // }
  // closeCarouselModalIsOpen = () => {
  //   this.setState({carouselModalIsOpen: false});
  // }

  // openBasket = () => {
  //   this.setState({basketIsOpen: true});
  // }
  
  closeBasket = () => {
    this.setState({basketIsOpen: false});
  }

  updateIsMobile = () => {
    this.setState({ isMobile: window.innerWidth <= 850 });
  }

  async componentDidMount(){
    const { getApiResp_SetShop, setShopId, shopsData } = this.props.myShopApiStore;
    // if(!shopsData){
      // setShopId(1); // added for 'katif'
    // }

    await getApiResp_SetShop()

    window.addEventListener('resize', this.updateIsMobile);

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile);
  }

  closeModal = () => {
      this.setState({modalIsOpen: false})
  }

  toggleBasket = () => {
    const { isCartItemUpdating, isPackItemUpdating } = this.props.myShopApiStore;

    if(!isCartItemUpdating && !isPackItemUpdating){
      this.setState({basketIsOpen: !this.state.basketIsOpen})
    }
  }

  handleCloseStore = () => {
    const { closeStore, isPackItemUpdating, isCartItemUpdating, getCart } = this.props.myShopApiStore;
    if(isPackItemUpdating || isCartItemUpdating){
      getCart().cartItemList.pop();
    }

    closeStore();
  }

  getCartIcon = () => {
    const { mainColor } = this.props.myShopApiStore;
    switch(mainColor){
      case '#EB0028':
        return redCart
      case '#1BB449':
        return greenCart
      case '#00B1E1':
        return blueCart
      case '#F1A103':
        return yellowCart
      default:
        return blackCart
    }
  }

  render() {
    // const { contextMenuVisible, userSettingsVisible, selectedGroupId, popup } = this.props.helperStore;
    const { userToken, mainColor, userParams, closeStore, shop, cartPrice, shopsData, shopId, getCart, my_env } = this.props.myShopApiStore;
    const { setActiveTopMenu, activeTopMenu } = this.props.ifaceMngr;

    // const isSingleStore = !shopsData && shopId === 1;
    const isSingleStore = !shopsData;

    // const isfullscreen =  window.matchMedia("(max-width: 850px)").matches

    let itemsInCart = 0;
    getCart() && getCart().cartItemList.forEach(cartItem => {cartItem.isReadyToOrder && itemsInCart++})
    const curr = "₪";

    

    if(!shop){
      return null;
    }
    return (
      <div
        className={style.TopApp}
        style={{  backgroundColor: '#DEDEE0', height: '100%', 
                  backgroundImage: my_env.url.indexOf("fast") >= 0 ? 'none' : this.props.myShopApiStore.fileBg,
                }}
      >
      {
      my_env.url.indexOf("fast") >= 0
      ?
        <ReactWhatsapp style={{cursor: 'pointer', position: 'absolute', top: '-1000px'}} number={process.env.REACT_APP_WHATSAPP_PHONE} message={process.env.REACT_APP_WHATSAPP_MSG}>
          <div className={style.Whatsapp} >
              <img src={whatsapp} width="55px" alt='' />
          </div>
        </ReactWhatsapp>
      :
        ''
      }

        {/* <CarouselModal modalIsOpen={this.state.carouselModalIsOpen} closeModal={this.closeCarouselModalIsOpen} shop={shop}/> */}
        <WelcomeModal modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal}/>
        <div className={style.App}>
          <div className={style.AppHeader}>
          {!isSingleStore && !this.state.isMobile &&
            <div className={style.GoToStoresBtn}>
              <div className={style.StoresImg}>
                <LazyLoadImage effect='blur' title='למסך חנויות' src={stores} alt="Stores" onClick={this.handleCloseStore} /> 
              </div>
            </div>
          }
          {
            !this.state.isMobile
            ?
              <div className={style.AppHeader__Login}>
                <div className={style.SocialIcons}>
                    {
                      shop.linkFacebook
                      ?
                        <img alt="" src={facebook} width="40px" onClick={()=> window.open(shop.linkFacebook, "_blank")}/>
                      :
                        ''
                    }
                    {
                      shop.phoneWhatsapp
                      ?
                        <ReactWhatsapp style={{backgroundColor: 'transparent', border: 'unset',
                                               outline: 'none', width: "50px"}}
                                       number={shop.phoneWhatsapp} message=''>
                            <img alt="" src={whatsappIcon} width="100%" height="100%"/>
                        </ReactWhatsapp>
                      :
                        ''
                    }
                </div>
                <div className={style.DivLogin}>
                  <div
                    className={style.LogIconDiv}
                    onClick={() =>
                      !userToken &&
                      this.props.ifaceMngr.setActiveTopMenu("Registration")
                    }
                    title={userToken ? "מחובר/ת" : "התחבר/י"}
                  >
                    <LazyLoadImage
                      effect='blur'
                      className={style.LoginIcon}
                      src={loginicon}
                      alt="login"
                    />
                  </div>
                  <div className={style.LoginText}>
                    {userToken ? (
                      <>
                        <div>שלום,</div>
                        <div>{userParams.name}</div>
                      </>
                    ) : (
                      <>
                        <div>שלום אורח/ת</div>
                        <div className={style.LoginHere}>התחבר/י</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            :
              <div className={style.CartInfo} onClick={this.toggleBasket}>{/*for mobile size*/}
                {
                  shop.isBusiness
                  ?
                    ''
                  :
                    <div className={style.CartInfoTitle}>
                        סה"כ
                        לתשלום
                    </div>
                }
                <div className={style.CartSum}>
                  <span className={style.CartSumSh}>{shop.isBusiness ? '' : curr}</span>
                  {
                    shop.isBusiness
                    ?
                      ''
                    :
                      <span className={style.CartSumVal}>
                        <NumberFormat
                          value={cartPrice}
                          // prefix={curr}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                        />
                      </span>
                  }
                </div>
                <div className={style.CartWrapper}>
                  <span className={style.ItemsInCart} style={{backgroundColor: mainColor}}>
                    <span>{itemsInCart}</span>
                  </span>
                  <img className={style.CartImg} src={this.getCartIcon()} alt="cart" style={{backgroundColor: "transparency"}} />
                </div>
              </div>
          }
            <div className={style.AppTopMenu}>
              <TopMenu />
            </div>

            {
              this.state.isMobile
              ?
                ''
              :
                <div className={style.AppLogo} onClick={() => setActiveTopMenu("ShopMenu")}>
                {shop && <LazyLoadImage effect='blur' src={shop.image && !isSingleStore ? shop.image : my_env.url + '/logo.png'} width={shop.image && !isSingleStore ? '100%' : '90%'} className="AppLogo" alt="AppLogo" />}
                </div>
            }  

          </div>

          <div id="appBody" className={style.AppBody}>
            {this.props.myShopApiStore.isMaintenance ? (
              <Maintenance />
            ) : (
              <AppBody basketIsOpen={this.state.basketIsOpen} closeBasket={this.closeBasket} />
            )}
          </div>

          {
            !this.state.isMobile
            ?
              <div id="appFooter" className={style.AppFooter}>
                <Footer />
              </div>
            :
              ""
          }
        </div>
      </div>
    );
  }
}
export default MainScreen;
